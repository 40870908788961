import { IconButton } from '@synoptic/ui-kit/button/icon-button.js';
import { WarningCircleIcon } from '@synoptic/ui-kit/icons/react/warning-circle.js';
import { XmarkIcon } from '@synoptic/ui-kit/icons/react/xmark.js';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@synoptic/ui-kit/tooltip/tooltip.js';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Delayed } from '../delayed';
import {
  uploadPreview,
  uploadPreviewContainer,
  uploadPreviewContainerCompact,
  uploadPreviewError,
  uploadPreviewLoader,
  uploadPreviewRemoveCompact,
  uploadPreviewRemoveContainer,
  uploadPreviewRemoveContainerCompact,
} from './upload-preview.css';

type UploadPreviewProps = {
  file: File;
  state: 'error' | 'loading' | 'success';
  onRemove: () => void;
  progress?: number;
  spinnerDelayMs?: number;
  compact?: boolean;
};

export const UploadPreview: React.FC<UploadPreviewProps> = ({
  state,
  progress,
  file,
  onRemove,
  spinnerDelayMs = 3000,

  compact,
}) => {
  const [previewSrc, setPreviewSrc] = useState(() => URL.createObjectURL(file));

  const [prevFile, setPrevFile] = useState(file);

  if (prevFile !== file) {
    setPrevFile(file);
    setPreviewSrc(URL.createObjectURL(file));
  }

  useEffect(() => {
    return () => URL.revokeObjectURL(previewSrc);
  }, [previewSrc]);

  const iconSize = compact ? 25 : 32;

  return (
    <div
      className={clsx(
        uploadPreviewContainer,
        compact && uploadPreviewContainerCompact,
      )}
    >
      {state === 'loading' ? (
        <Delayed delayMs={spinnerDelayMs}>
          <div className={uploadPreviewLoader}>
            {`${compact ? '' : 'Uploading...'}${progress ? `${progress}%` : ''}`}
          </div>
        </Delayed>
      ) : null}

      {state === 'error' ? (
        <div className={uploadPreviewError}>
          <WarningCircleIcon width={iconSize} height={iconSize} />
        </div>
      ) : null}

      <div
        className={clsx(
          uploadPreviewRemoveContainer,
          compact && uploadPreviewRemoveContainerCompact,
        )}
      >
        <Tooltip>
          <TooltipTrigger asChild>
            {/* TODO: refactor tooltip to allow Button be composed with asChild */}
            <div>
              <IconButton
                variant="tertiary"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
                className={clsx(compact && uploadPreviewRemoveCompact)}
                size="small"
              >
                <XmarkIcon />
              </IconButton>
            </div>
          </TooltipTrigger>
          <TooltipContent>Remove media</TooltipContent>
        </Tooltip>
      </div>

      {file.type.startsWith('video/') ? (
        <video
          controls
          playsInline
          className={uploadPreview}
          src={previewSrc}
        />
      ) : (
        <img className={uploadPreview} src={previewSrc} />
      )}
    </div>
  );
};
